<template>
  <el-select
    v-model="categoryModel"
    @change="categoryChanged"
    :placeholder="placeholder ? placeholder : $t('COMMON.CATEGORY')"
    :filterable="filterable"
    :multiple="false"
    :disabled="disabled"
    remote
    :remote-method="getCategories"
    :loading="loading"
  >
    <el-option-group
      v-for="(group, key) in categoriesGrouped"
      :key="key"
      :label="group.label"
    >
      <el-option
        v-for="item in group.options"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-option-group>
  </el-select>
</template>

<script>
import { Option, Select, OptionGroup } from "element-ui";

export default {
  name: "category-group-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    category: {
      type: String,
      default: null,
      description: "Category id",
    },
    placeholder: {
      type: String,
      default: null,
      description: "Placeholder",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterIdsNotIn: {
      type: Array,
      default: () => [],
      description: "Categories not in list id",
    },
  },

  data() {
    return {
      categoryModel: this.category,
      categories: {},
      categoriesGrouped: [],
      query: null,
    };
  },

  setup() {},

  created() {
    this.getCategories(null, this.category);
  },

  methods: {
    async getCategories(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "-created_at",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 999,
          },
          include: "parent,subCategories",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }

        if (this.filterIdsNotIn) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              idsNotIn: this.filterIdsNotIn,
            },
          };
        }

        await this.$store.dispatch("categories/list", params);
        const categoriesArr = await this.$store.getters["categories/list"];
        this.categoriesGrouped = this.groupCategories(categoriesArr);
        this.categories = {};
        categoriesArr.forEach((category) => {
          this.categories[category.id] = category;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    categoryChanged(categoryId) {
      const category = Object.values(this.categories).find(
        (item) => item.id === categoryId
      );
      this.$emit("categoryChanged", categoryId, category);
    },

    groupCategories(categories) {
      const categoriesGrouped = [];
      categories.map((item) => {
        const options = [];
        options.push(item);
        categories.forEach((element) => {
          if (element.parent?.id === item.id) {
            options.push(element);
          }
        });
        categoriesGrouped.push({
          parent: item.parent,
          subCategories: item.subCategories,
          label: item.name,
          options,
        });
      });

      return categoriesGrouped.filter(
        (item) => item.subCategories.length || item.parent === null
      );
    },
  },

  watch: {
    category(category) {
      if (category) {
        this.categoryModel = category;
        if (category !== this.categoryModel) {
          this.getCategories(null, category);
        }
      } else {
        this.categoryModel = null;
      }
    },
    filterOrganization() {
      this.getCategories();
    },
    filterIdsNotIn() {
      this.getCategories();
    },
  },
};
</script>
